<template>
  <div v-if="isLoading" class="loader-container">
    <div class="loader"></div>
  </div>
  <ckeditor
      v-model="editorData"
      :editor="editor"
      :config="editorConfig"
      @ready="onEditorReady"
  ></ckeditor>
  <button v-if="!isLoading" class="submit" @click="submit">Opslaan</button>
</template>
<script setup>
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  Underline,
  List,
  Font,
  Strikethrough,
  Heading,
  EasyImage,
  Link,
  Image,
  Base64UploadAdapter,
  ImageUpload,
  CloudServices,
  ImageInsertUI,
  AutoImage, ImageInsertViaUrl, ImageResizeEditing, ImageResize, ImageStyle, ImageToolbar, LinkImage
} from 'ckeditor5';
import {Comments, TrackChanges, TrackChangesData} from 'ckeditor5-premium-features';
import coreTranslations from 'ckeditor5/translations/nl.js';
import premiumFeaturesTranslations from 'ckeditor5-premium-features/translations/nl.js';

import {computed, onMounted, ref} from 'vue';

import 'ckeditor5/ckeditor5.css';
import 'ckeditor5-premium-features/ckeditor5-premium-features.css';

import {UsersInit} from "@/plugins/UsersPlugin.js";
import {CommentsIntegration} from "@/plugins/CommentsPlugin.js";
import {TrackChangesIntegration} from "@/plugins/TrackChangesPlugin.js";
import S3UploadPlugin from "@/plugins/S3UploadPlugin.js";

const editor = ClassicEditor;
const editorData = ref('');
const editorConfig = {
  plugins: [
    Bold,
    Font,
    Heading,
    Image,
    ImageToolbar,
    ImageResize,
    ImageUpload,
    ImageInsertViaUrl,
    ImageInsertUI,
    ImageStyle,
    S3UploadPlugin,
    LinkImage,
    Link,
    Strikethrough,
    Essentials,
    Italic,
    Mention,
    Paragraph,
    Undo,
    Underline,
    List,
    TrackChanges,
    TrackChangesData,
    Comments,
    UsersInit,
    CommentsIntegration,
    TrackChangesIntegration,
  ],
  toolbar: [
    'heading',
    'fontFamily',
    'fontSize',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'bulletedList',
    'numberedList',
    '|',
    'link',
    'insertImage',
    'undo',
    'redo',
    '|',
    'comment',
    'commentsArchive',
    'trackChanges',
  ],
  translations: [
    coreTranslations,
    premiumFeaturesTranslations,
  ],
  licenseKey: 'Ynh4M3hDTk1ZZEZvUnZjMlJMbkVqOGk4cll1TzNDV0FoWWx2dFd0cnJqWUVoZUNMa1drN3pZbCtBTVZHcmc9PS1NakF5TkRBNU1UTT0=',
  comments: {
    editorConfig: {
      extraPlugins: [
        Bold,
        Italic,
        Underline,
        List,
      ]
    }
  },
  image: {
    integrations: [ 'url' ],
    toolbar: [
      'imageStyle:inline',
      'imageStyle:block',
      'imageStyle:side'
    ],
    insert: {
      // If this setting is omitted, the editor defaults to 'block'
      type: 'auto'
    }
  }

};

let eventOrigin;
window.addEventListener('message', function (event) {
  console.log(event);

  eventOrigin = event.origin || event.originalEvent.origin;
  // if (eventOrigin !== /*the container's domain url*/)
  //   return;

  if (typeof event.data == 'object' && event.data.call === 'editorMessage') {
    editorData.value = event.data.initialData;
  }
}, false);

const displayHtml = computed(() => {
  return editorData.value
      // Remove content inside <suggestion-start name="insertion:..."></suggestion-start> and <suggestion-end name="insertion:..."></suggestion-end>
      .replace(/<suggestion-start name="insertion:[^>]*>.*?<suggestion-end name="insertion:[^>]*>/gs, '')
      // Remove <suggestion-start> and <suggestion-end> tags but keep their content
      .replace(/<\/?suggestion-(start|end)[^>]*>/g, '')
      // Remove <comment-start> and <comment-end> tags but keep their content
      .replace(/<\/?comment-(start|end)[^>]*>/g, '');
});


let editorPlugin;
let trackChangesPlugin;
let trackChangesDataPlugin;
let commentPlugin;

const isLoading = ref(true);

const onEditorReady = (editor) => {
  editor.execute('trackChanges');
  console.log(editor);
  editorPlugin = editor;
  trackChangesPlugin = editor.plugins.get('TrackChanges');
  trackChangesDataPlugin = editor.plugins.get('TrackChangesData');
  commentPlugin = editor.plugins.get('CommentsRepository');
  console.log(isLoading.value);
  isLoading.value = false;
};

// TODO: Remove this after testing
// onMounted(() => {
//   setTimeout(() => {
//     window.postMessage({'call': 'editorMessage', 'initialData': '' +
//           '<figure class="image image_resized" style="width:37.59%;"><img style="aspect-ratio:1236/938;" src="https://nha-editor-media-development-bucket.s3.eu-west-1.amazonaws.com/Screenshot%202024-03-21%20123747.png" width="1236" height="938"></figure><p><comment-start name="e1991abde4c6cc22df9e1ea0f99f3faee:43c2d"></comment-start>Test<comment-end name="e1991abde4c6cc22df9e1ea0f99f3faee:43c2d"></comment-end></p>'});
//     window.postMessage({
//       'call': 'usersMessage',
//       'usersData': [{'id': '1', 'name': 'Niels'}, {'id': '2', 'name': 'Chris'}],
//       'userCurrent': '3',
//       'userCurrentName': 'Joey',
//     });
//     window.postMessage({'call': 'commentsMessage', 'commentsData': [
//         {
//           attributes: [],
//           comments: [{
//             attributes: [],
//             authorId: '3',
//             comment: 'eb137ccfba8243aac12f2f35e67a860c1',
//             content: '<p>Test</p>',
//             createdAt: new Date(),
//           }],
//           resolvedAt: null,
//           resolvedBy: null,
//           threadId: 'e1991abde4c6cc22df9e1ea0f99f3faee',
//           unlinkedAt: null,
//         }
//       ]});
//     window.postMessage({'call': 'suggestionsMessage', 'suggestionsData': []});
//   }, 250);
// });

const parseSuggestionsObject = (suggestionsData) => {
  suggestionsData = suggestionsData.map((suggestion) => {
    return {
      id: suggestion.id,
      type: suggestion.type,
      authorId: suggestion.author.id,
      createdAt: suggestion.createdAt,
      data: suggestion.data,
      attributes: suggestion.attributes,
    }
  });

  return suggestionsData;
}

const parseCommentsObject = (threadsData) => {
  threadsData = threadsData.map((thread) => {
    const comments = thread.comments.map((comment) => {
      return {
        commentId: comment.id,
        content: comment.content,
        createdAt: comment.createdAt,
        authorId: comment.author.id,
        attributes: comment.attributes,
      }
    });

    return {
      threadId: thread.id,
      unlinkedAt: thread.unlinkedAt,
      resolvedAt: thread.resolvedAt,
      resolvedBy: thread.resolvedBy?.id,
      attributes: thread.attributes,
      comments,
    }
  });

  return threadsData;
}

const parseUsersObject = (usersData) => {
  usersData = usersData
    // Filter out the anonymous user
    .filter((user) => user.id !== 'anonymous-user')
    // Map the user to only return id and name
    .map((user) => {
      return {
        id: user.id,
        name: user.name,
      }
    });

  return usersData;
}

const submit = () => {
  const editorHtml = editorPlugin
      .getData();

  const previewHtml = editorPlugin
      .getData({ showSuggestionHighlights: true });

  const commentsData = commentPlugin
      .getCommentThreads({
        skipNotAttached: true,
        skipEmpty: true
      });

  const suggestionsData = trackChangesPlugin
      .getSuggestions();

  const usersData = editorPlugin.plugins.get('Users').users;

  const dataObject = {
    'editorHtml': editorHtml,
    'previewHtml': previewHtml,
    'displayHtml': displayHtml.value,
    'commentsData': parseCommentsObject(commentsData),
    'suggestionsData': parseSuggestionsObject(suggestionsData),
    'usersData': parseUsersObject(usersData),
  };

  // Deep copy to prevent errors
  const messageObject = JSON.parse(
      JSON.stringify({
        call: 'editorData',
        value: dataObject
      })
  );

  window.parent.postMessage(messageObject, eventOrigin);
}

</script>
<style>

#app {
  width: 100vw;
  height: 100vh;
  //display: flex;
  flex-direction: column;
}
/*

.wrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.ck {
  flex: 1;
}
*/

.editor-container__sidebar {
  padding: 0 10px;
  position: relative;
  min-width: 100px;
  font-size: 20px;
  background: hsl(0, 0%, 98%);
  border: 1px solid hsl(0, 0%, 77%);
  border-left: 0;
  overflow: hidden;
  min-height: 100%;

  /* #2733. Do not overlap the left border if the sidebar is longer than content. */
  box-shadow: -1px 0 0 0 hsl(0, 0%, 77%);
}
button.submit {
  background-color: #004D91;
  color: white;
  border: 0;
  padding-top: .5rem;
  padding-bottom: .5rem;
  width: 100vw;
  cursor: pointer;
}
.ck.ck-editor__main > .ck-editor__editable {
  height: calc(100vh - 72px);
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #004D91;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Fix for the CSS linter errors */
#app {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

/* ... (rest of the styles remain the same) */
</style>
