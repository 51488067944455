import './assets/main.css'

import { createApp } from 'vue'
import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue';
import App from './App.vue'

const app = createApp(App)

app.use( CkeditorPlugin )

app.mount('#app')
