import { FileRepository, Plugin } from 'ckeditor5';

import Adapter from './AwsUploadAdapter.js';

export default class S3UploadPlugin extends Plugin {

    static get requires() {
        return [FileRepository];
    }

    static get pluginName() {
        return 'S3UploadPlugin';
    }

    init() {
        // Get the bucket name from the .env file
        const region = import.meta.env.VITE_APP_AWS_REGION;
        const bucket = import.meta.env.VITE_APP_AWS_BUCKET;
        const identityPoolId = import.meta.env.VITE_APP_AWS_IDENTITY_POOL_ID;

        if (!bucket || !region || !identityPoolId) {
            console.warn(' .env is not configured correctly')
            return;
        }

        this.editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            console.log('Creating upload adapter');
            console.log(loader);

            return new Adapter(loader, {
                region,
                bucket,
                identityPoolId
            });
        }
    }
}