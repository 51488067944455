import * as UploadAdapter from 'ckeditor5';
import AWS, { CognitoIdentityCredentials } from 'aws-sdk';

export default class AwsUploadAdapter {
    s3;

    constructor(loader, s3Data) {
        this.implementsInterface(this, UploadAdapter);
        console.log('AwsUploadAdapter created');
        console.log(loader);
        this.loader = loader;
        this.s3Data = s3Data;
    }

    implementsInterface(obj, interfaceObj) {
        for (const method in interfaceObj) {
            if (!(method in obj) ||
                typeof obj[method] !== "function") {
                return false;
            }
        }
        return true;
    }

    upload() {
        return this.loader.file
            .then(file => new Promise( ( resolve, reject ) => {
                this.getCredentials();
                this.uploadImage(file, resolve, reject);
            }));
    }

    abort() {
        //TODO::implement?
    }

    getCredentials() {
        AWS.config.update({
            region: this.s3Data.region,
            credentials: new CognitoIdentityCredentials({
                IdentityPoolId: this.s3Data.identityPoolId,
            })
        });

        this.s3 = new AWS.S3();
    }


    uploadImage(file, resolve, reject) {
        console.log('Start upload');
        console.log(file);
        let s3 = this.s3;
        console.log('Uploading image');
        //const decodedImage = Buffer.from(file, 'base64');
        console.log('Decoded image');
        const filePath = file.name;
        const params = {
            'Body': file,
            'Bucket': this.s3Data.bucket,
            'Key': filePath,
            'ACL': 'public-read', /* This makes the image public, but only works if your S3 bucket allows public access */
            'ContentType': file.type /* This is important to handle jpg vs png etc */
        };
        s3.upload(params, function (err, data) {
            if (err) {
                reject(err);
            } else {
                /**
                 * data returned after upload will look like this:
                 *          Bucket: "bucketName"
                 Key: "somefilenametouseinS3.png"
                 Location: "URL to your image"
                 */
                resolve({
                    default: data.Location
                });
            }
        });
    }
}
/*
export default function AwsUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new AwsUploadAdapter(loader);
    };
}*/
